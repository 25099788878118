interface MenuItem {
  name: string;
  path: string;
  target: string;
}

interface Props {
  onLinkClick?: () => void;
  menu: Array<Array<MenuItem>> | null;
  menuClass?: string;
}

export const UserLinks = ({ onLinkClick, menu, menuClass }: Props) => {

  // for links that open in a new window, close the dropdown menu
  // this is on desktop only
  const handleLinkClick = (linkTarget: string) => {
    if (onLinkClick && linkTarget === "_blank") {
      onLinkClick();
    }
  }
  return (
    <>
      {menu?.map((m1, index) => (
        <ul key={`m1-${index}`} className={`user-links ${menuClass}`}>
          {m1
            .filter((m2) => !!m2.name)
            .map((m2) => (
              <li className="user-links__item" key={m2.path}>
                <a
                  href={m2.path}
                  target={m2.target}
                  onClick={() => handleLinkClick(m2.target)}
                  className="user-links__link"
                >
                  {m2.name}
                </a>
              </li>
            ))}
        </ul>
      ))}
    </>
  );
};
