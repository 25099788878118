import { useEffect, useState } from "react";

import ReactDOM from "react-dom";
import { useCxp } from "../../hooks/useCxp";
import { UserLinks } from "../user/userLinks";

interface MenuItem {
	name: string;
	path: string;
	target: string;
}

/*
    Note: If you want to test this outside of PhaseZero's proxy, you will need to sign in to their UAT site:
    https://cxpoptimauat.phasezero.xyz
    Username: bisma.ali@americaneagle.com
    Password: Password1!

    Their JS is dependent on local storage - but because we are not on the same domain yet, the local storage values are missing. So,
    after signing in, copy over the following local storage items from the cxpoptimauat.phasezero.xyz browser tab, to your local
    Sitefinity browser tab:
    - userPermissionsList
    - user_profile
    - selectedMarket
    - currentUserProfileData
    - bearerToken



    This is an undocumented workaround that I found
*/

export default function MobileUserLinks() {
	const container = document.getElementById("pzMobileUserLinks");
	const { ready } = useCxp();
	const [menu, setMenu] = useState<Array<Array<MenuItem>> | null>(null);

	useEffect(() => {
		if (ready) {
			const menu = cxpJS.getMenuItems() as Array<Array<MenuItem>>;
			if (menu && menu.length > 0) {
				setMenu(menu);
			}
		}
	}, [ready]);

	return (
		container &&
		ReactDOM.createPortal(
			<>
				{(ready || menu) && <UserLinks menu={menu} />}
				<ul className="main-nav__menu main-nav__menu--secondary">
					<li className="main-nav__item">
						<a
							href="/orders/order-details/?type=guest-order"
							className="main-nav__link"
						>
							Orders
						</a>
					</li>
					<li className="main-nav__item">
						<a href="/regions" className="main-nav__link main-nav__link--secondary">
							<svg className="main-nav__link-icon" aria-hidden="true">
								<use href="/ResourcePackages/Talon/assets/dist/sprites/svg-optima.svg#icon-globe"></use>
							</svg>
							English
						</a>
					</li>
				</ul>
			</>,
			container
		)
	);
}
