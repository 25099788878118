import { useEffect, useState } from "react";

import ReactDOM from "react-dom";
import { UserLinks } from "../user/userLinks";
import { useCxp } from "../../hooks/useCxp";
import { useOutsideClick } from "../../hooks/useOutsideClick";

interface MenuItem {
	name: string;
	path: string;
	target: string;
}

/*
    Note: If you want to test this outside of PhaseZero's proxy, you will need to sign in to their UAT site:
    https://cxpoptimacms.phasezero.xyz or 
    https://cxpoptimacmsuat-b.phasezero.xyz/
    Username: bisma.ali@americaneagle.com
    Password: Password1!

    Their JS is dependent on local storage - but because we are not on the same domain yet, the local storage values are missing. So,
    after signing in, copy over the following local storage items from the cxpoptimauat.phasezero.xyz browser tab, to your local
    Sitefinity browser tab:
    - userPermissionsList
    - user_profile
    - selectedMarket
    - currentUserProfileData
    - bearerToken



    This is an undocumented workaround that I found
*/

export default function CxpNav() {
	const container = document.getElementById("cxpNav");
	const { ready } = useCxp();
	const [menu, setMenu] = useState<Array<Array<MenuItem>> | null>(null);
	const [open, setOpen] = useState(false);
	const [cartCount, setCartCount] = useState(0);

	const navItems = [
		{
			text: "Orders",
			icon: "icon-archive",
			url: "/orders/order-details/?type=guest-order",
		},
		{
			text: "Cart",
			icon: "icon-cart",
			url: "/view-cart",
		},
		{
			text: "English",
			icon: "icon-globe",
			url: "/regions",
		},
	];

	// Close any dropdowns when clicking outside of the component
	const ref = useOutsideClick(() => {
		setOpen(false);
	});

	useEffect(() => {
		if (ready) {
			const menu = cxpJS.getMenuItems() as Array<Array<MenuItem>>;
			if (menu && menu.length > 0) {
				setMenu(menu);
			}

			const count = cxpJS.getCartCount();
			if (count) {
				setCartCount(count);
			} else {
				setCartCount(0);
			}
		}
	}, [ready]);

	return (
		container &&
		ReactDOM.createPortal(
			<>
				<div className="cxp-nav" ref={ref}>
					<ul className="cxp-nav__list">
						{!ready || !menu ? (
							<li className="cxp-nav__item" key={"login-link"}>
								<a href={"/login"} className="cxp-nav__link">
									<svg
										className="cxp-nav__icon"
										aria-hidden="true"
									>
										<use
											href={`/ResourcePackages/Talon/assets/dist/sprites/svg-optima.svg#icon-login`}
										></use>
									</svg>
									<span className="cxp-nav__text">Login</span>
								</a>
							</li>
						) : ready && menu ? (
							<li className="cxp-nav__item">
								<button
									id="accountToggle"
									type="button"
									className="cxp-nav__link cxp-nav__link--toggle"
									aria-expanded={open ? "true" : "false"}
									onClick={() => setOpen(!open)}
								>
									<svg
										className="cxp-nav__icon"
										aria-hidden="true"
									>
										<use href="/ResourcePackages/Talon/assets/dist/sprites/svg-optima.svg#icon-account"></use>
									</svg>
									<span className="cxp-nav__text">
										My Account
									</span>
								</button>
								<div
									className={`cxp-nav__dropdown ${
										open ? "cxp-nav__dropdown--open" : ""
									}`}
									aria-labelledby="accountToggle"
								>
									<UserLinks
										onLinkClick={() => setOpen(false)}
										menu={menu}
										menuClass="utility-nav__sub"
									/>
								</div>
							</li>
						) : (
							""
						)}
						{navItems.map((item) => (
							<li className="cxp-nav__item" key={item.text}>
								<a href={item.url} className="cxp-nav__link">
									{item.text === "Cart" && (
										<span className="cxp-nav__info">
											{cartCount}
										</span>
									)}
									<svg
										className="cxp-nav__icon"
										aria-hidden="true"
									>
										<use
											href={`/ResourcePackages/Talon/assets/dist/sprites/svg-optima.svg#${item.icon}`}
										></use>
									</svg>
									<span className="cxp-nav__text">
										{item.text}
									</span>
								</a>
							</li>
						))}
					</ul>
				</div>
			</>,
			container
		)
	);
}
