import { ActiveMarker, Pin } from "./findARetailer";
import { useEffect, useMemo, useRef, useState } from "react";

export default function GoogleMap({
  retailers,
  activeMarker,
  radius,
  zipCode,
  country,
}: {
  retailers: any[] | undefined;
  activeMarker?: ActiveMarker;
  radius: number;
  zipCode: string | undefined | null;
  country: string;
}) {
  const ref = useRef(null);
  const [map, setMap] = useState<google.maps.Map | undefined>(undefined);
  const [pins, setPins] = useState<Pin[]>([]);
  const markersRef = useRef<google.maps.Marker[]>([]);
  const [radiusCircle, setRadiusCircle] = useState<google.maps.Circle | undefined>(undefined);
  const [circleCoordinates, setCircleCoordinates] = useState<google.maps.LatLng>();
  const [infoWindow, setInfoWindow] = useState<
    google.maps.InfoWindow | undefined
  >(undefined);
  const markerImage = require("../../images/drop-pin-edited.png");


  // info window is shown via hover on markers and result items, no click events
  const updateInfoWindow = (marker: google.maps.Marker, pin: Pin) => {
    infoWindow?.close();

    infoWindow?.setContent(
      `
      <div class="info-window">
        <span class="info-window__distance">${pin.distance}</span>
        <h3 class="info-window__title">${pin.title}</h3>
        <address class="info-window__address">${pin.address}</address>
        ${
          pin.phone &&
          `<a href="tel:${pin.phone}" class="info-window__link">
            <svg aria-hidden="true" class="info-window__icon">
              <use href="#icon-phone"></use>
            </svg>
            ${pin.phone}
          </a>`
        }
        <a href="https://maps.google.com/?q=${pin.pos.lat()},${pin.pos.lng()}" target="_blank" class="info-window__link">
          Directions
          <span class="visually-hidden">(opens in a new window)</span>
        </a>
      </div>
      `
    );

    infoWindow?.open({
      anchor: marker,
      map,
    });
  }
  
  useEffect(() => {
    // need to place a radius circle on the map, centered on the zip code location
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({'address': `${zipCode},${country}`}, function(results, status) {
      if (status === 'OK' && results && results.length > 0) {
        const coordinates = new google.maps.LatLng(results[0].geometry.location.lat(), results[0].geometry.location.lng());
        setCircleCoordinates(coordinates);
      }
    }); 
  },[zipCode, radius]);

  // Here we create the google map instance. This should only happen once
  useEffect(() => {
    setMap(
      new window.google.maps.Map(ref.current!, {
        center: { lat: 37.2756718, lng: -104.6558724 }, // US
        zoom: 5,
        mapTypeControl: false,
      })
    );

    setInfoWindow(new window.google.maps.InfoWindow());
  }, []);

  useEffect(() => {
    if (map) {
      if (pins.length > 0) {
        // if we have active markers, we remove them from the map and remove the event listeners
        markersRef.current.forEach((m) => {
          m.setMap(null);
          window.google.maps.event.clearInstanceListeners(m);
        });
  
        markersRef.current = pins.map((pin,index) => {
          const markerLabel = index < 9 ? `0${index + 1}` : `${index + 1}`;
          const marker = new window.google.maps.Marker({
            position: pin.pos,
            map: map,
            label: {
              text: markerLabel,
              color: '#fff',
              fontWeight: "bold",
            },
            icon: markerImage,
          });
  
          google.maps.event.addListener(marker, "mouseover", () => {
            updateInfoWindow(marker, pin);
          });
  
          return marker;
        });
  
        radiusCircle?.setMap(null);
        // applies a radius circle on the map centered around the current zipcode
        setRadiusCircle(new google.maps.Circle({
          center: circleCoordinates,
          radius: 1609.344 * radius,
          strokeColor: "#0093c6",
          fillColor: "#000000",
          fillOpacity: .05,
          strokeOpacity: .6,
          strokeWeight: 1,
          map: map
        }));
        const zoomLevel = radius <= 20
          ? 10
          : radius === 25
          ? 9
          : radius === 50
          ? 8
          : 7;
        map.setCenter(pins?.[0]?.pos);
        map.setZoom(zoomLevel);
      } else {
        map.setCenter({ lat: 37.2756718, lng: -104.6558724 }); // US
        map.setZoom(5);
      }
    }
  },[pins]);

  // re center the map when clicking on an item in the results list
  useMemo(() => {
    if (activeMarker) {
      map?.setCenter(activeMarker);
      // show the info window of the active marker
      const marker: google.maps.Marker | undefined = markersRef.current.find((x) => (x.getPosition()?.lat() === activeMarker.lat && x.getPosition()?.lng() === activeMarker.lng));
      const activePin = pins.find((x) => x.pos.lat() === activeMarker.lat && x.pos.lng() === activeMarker.lng);
      if (marker && activePin) {
        updateInfoWindow(marker, activePin);
      }
    }
  }, [activeMarker]);

  // Whenever our retailers list changes, we map them to our pin objects and add them to map
  useMemo(() => {
    if (!!map) {

      const pinItems = retailers?.map((r) => {
        return {
          pos: new window.google.maps.LatLng(r.lat, r.lgt),
          distance: `${Math.round(r.distance * 100) / 100} Miles`,
          title: r.name,
          address: `
            ${r.addressLine1}<br/>
            ${r.addressLine2 ? `${r.addressLine2}<br/>` : ""}
            ${r.city} ${r.state} ${r.zip}`,
          website: r.website,
          phone: r.phone,
        }
      });

      if (pinItems) {
        setPins(pinItems);
      }
    }
  }, [retailers]);

  return (
    <>
      <div ref={ref} id="map" className="location-map" />
    </>
  );
}
