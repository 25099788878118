import { useEffect, useState } from "react";

import ReactDOM from "react-dom";
import { useCxp } from "../../hooks/useCxp";

interface ProfileItem {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

/*
    Note: If you want to test this outside of PhaseZero's proxy, you will need to sign in to their UAT site:
    https://cxpoptimauat.phasezero.xyz
    Username: bisma.ali@americaneagle.com
    Password: Password1!

    Their JS is dependent on local storage - but because we are not on the same domain yet, the local storage values are missing. So,
    after signing in, copy over the following local storage items from the cxpoptimauat.phasezero.xyz browser tab, to your local
    Sitefinity browser tab:
    - userPermissionsList
    - user_profile
    - selectedMarket
    - currentUserProfileData
    - bearerToken



    This is an undocumented workaround that I found
*/

export default function Username() {
  const container = document.getElementById("pzUsername");
  const { ready } = useCxp();
  const [profile, setProfile] = useState<ProfileItem | undefined>(undefined);

  useEffect(() => {
    if (ready) {
      // do stuff
      const profile = cxpJS.getUserProfile() as ProfileItem;
      if (profile) {
        setProfile(profile);
      }
    }
  }, [ready]);

  return (
    container &&
    ReactDOM.createPortal(
      <div className="mobile-user-actions">
        <div className="mobile-user-actions__header">
          <div className="mobile-user-actions__greeting">
            <svg className="mobile-user-actions__icon" aria-hidden="true">
              <use href="/ResourcePackages/Talon/assets/dist/sprites/svg-optima.svg#icon-account"></use>
            </svg>
            {!ready || !profile ? (
              <p>Hi Guest</p>
            ) : (
              <p>Hi {profile.firstName}</p>
            )}
          </div>
        </div>
        {!ready || !profile && (
            <div className="mobile-user-actions__options">
              <a className="mobile-user-actions__link mobile-user-actions__link--sign-up" href="/sign-up">Sign Up</a>
              <a className="mobile-user-actions__link mobile-user-actions__link--sign-in" href="/login">Sign In</a>
            </div>
        )}
      </div>,
      container
    )
  );
}
