import { ChangeEvent, useEffect, useState } from "react";

interface Props {
	options: any[] | undefined;
	activeDropdown: string | undefined;
	disabled: boolean;
	showInput?: boolean;
	title: string;
	displayType?: string;
	searchPlaceholder?: string;
	onChangeHandler: (value: string) => void;
	onDropdownChange: (status: boolean, title: string) => void;
}

export default function FormDropdown({
	options,
	activeDropdown,
	disabled,
	showInput,
	title,
	displayType,
	searchPlaceholder,
	onChangeHandler,
	onDropdownChange,
}: Props) {
	const [dropdownStatus, setDropdownStatus] = useState<boolean>(false);
	const [filter, setFilter] = useState<string>('');

	const handleOptionChange = (value: string) => {
		setDropdownStatus(false);
		onChangeHandler(value);
	}

	// can filter results inside the dropdown
	const onInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
		const value = e.target.value.toUpperCase();
		setFilter(value);
	}

	// only one dropdown can show at a time
	// parent component handles which dropdown is active
	const onDropdownToggle = () => {
		onDropdownChange(!dropdownStatus, title);
	}

	useEffect(() => {
		setDropdownStatus(activeDropdown === title);
	},[activeDropdown]);

	return (
		<div className="form-dropdown">
			<button type="button" className="form-dropdown__toggle" onClick={onDropdownToggle} disabled={disabled} aria-expanded={dropdownStatus}>{title}</button>
			<div className={`form-dropdown__dropdown ${dropdownStatus ? 'active' : ''}`}>
				<div className="form-dropdown__container">
					{
						showInput &&
						<input type="text" className="form-control form-dropdown__input" placeholder={searchPlaceholder} onChange={onInputChange}/>
					}
					<ul className={`form-dropdown__list ${displayType === 'secondary' ? 'form-dropdown__list--secondary' : ''}`}>
						{options?.filter((option) => option.toUpperCase().includes(filter)).map((option) => (
						<li key={`year-${option}`} className="form-dropdown__item">
							<button type="button" className="form-dropdown__button" onClick={() => handleOptionChange(option)}>{option}</button>
						</li>
						))}
					</ul>
				</div>
			</div>
		</div>
	)
}