import { InputField, zipLengthError } from "../findYourBattery/form";
import { useCallback, useMemo, useState } from "react";

import ReactDOM from "react-dom";
import { debounce } from "lodash";
import { validateCAPostalCode } from "../../utilities/validateCanadaPostalCode";

export default function FindYourStore({
  onFinish,
}: {
  onFinish: (zip: string, radius: number, country: string) => void;
}) {
  const container = document.getElementById("find-your-store");

  const [zipCode, setZipCode] = useState<string>("");
  const [radius, setRadius] = useState<number>(15);
  const [country, setCountry] = useState<string>("US");
  const [toggleStatus, setToggleStatus] = useState<boolean>(false);
  const [zipCodeError, setZipCodeError] = useState<boolean>(false);

  const radiusChangeHandler = (e: any) => {
    setRadius(e.target.value);
  };

  const handleKeyUpEvent = (e:any) => {
    if (e.key === "Enter") {
      submitSearch();
    }
  };

  const inputChangeHandler = (e:any) => {
    setZipCode(e.target.value);
  };
  
  const submitSearch = () => {
    if (zipCode) {
      const country = validateCAPostalCode(zipCode);
      setCountry(country);
    }
    
    if (zipCode.length < 5) {      
      setZipCodeError(true);
    } else {
      setZipCodeError(false);
      onFinish(zipCode, radius, country)
    }
  }

  return (
    container &&
    ReactDOM.createPortal(
      <div className="store-finder">
        <button type="button" className={`utility-nav__link utility-nav__link--skew utility-nav__link--toggle ${toggleStatus ? 'active' : ''}`} aria-expanded={toggleStatus} onClick={() => setToggleStatus(!toggleStatus)}>
            <svg className="utility-nav__link-icon" aria-hidden="true">
              <use href="/ResourcePackages/Talon/assets/dist/sprites/svg-optima.svg#icon-location-pin"></use>
            </svg>
            <span className="utility-nav__link-text">Find Your Store</span>
        </button>

        <div className={`store-finder__dropdown ${toggleStatus ? 'active' : ''}`}>
          <h2 className="store-finder__title">Find your nearest optima retailer</h2>
          <button type="button" onClick={() => setToggleStatus(!toggleStatus)} className="store-finder__close">Close X</button>
          <div className="store-finder__group">
            <label className="store-finder__label">
              <span className="visually-hidden">Zip / Postal Code</span>
              <input
                className="store-finder__input form-control"
                type="text"
                placeholder="Enter Zip Code"
                onKeyUp={handleKeyUpEvent}
                onChange={inputChangeHandler}
              />
              {
                zipCodeError && <span className="store-finder__error">Please enter a 5-digit zip code</span>
              }
            </label>
          </div>
            
          <div className="store-finder__group">
            <div className="form__select-wrapper">
            <label>
              <span className="visually-hidden">Select search radius</span>
              <select className="store-finder__select form-control" value={radius ?? "15"} onChange={radiusChangeHandler}>
                <option value="5">Within 5 miles</option>
                <option value="10">Within 10 miles</option>
                <option value="15">Within 15 miles</option>
                <option value="20">Within 20 miles</option>
                <option value="25">Within 25 miles</option>
                <option value="50">Within 50 miles</option>
                <option value="75">Within 75 miles</option>
                <option value="100">Within 100 miles</option>
              </select>
            </label>
            </div>
          </div>

          <button
            className="store-finder__button button"
            type="button"
            onClick={() => submitSearch()}
          >
            Find a Retailer
          </button>
        </div>          
      </div>,
      container
    )
  );
}
