import {
  ProductResults,
  productSuggestionsApiEndpoint,
} from "./navigation/searchBox";
import { useEffect, useState } from "react";

import ReactDOM from "react-dom";
import { useCxp } from "../hooks/useCxp";
import useSWR from "swr";

const pzFetcher = (payload: { searchQuery: string }) => {
  return fetch(productSuggestionsApiEndpoint(), {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      apiKey: productSuggestionsApiEndpoint().includes("optimabatteries.com")
        ? "ab059892-4b55-47db-9411-94f8be7dd0e4"
        : "OptimaQAUGBMLyuweguw23+iuwe",
    },
    body: JSON.stringify(payload),
  }).then((res) => res.json());
};

export default function ProductSearch() {
  const container = document.getElementById("productSearch");
  const totalSitefinitySearchResults = container?.dataset.totalResults;
  const currentPage = Number(container?.dataset.currentPage);
  const urlParams = new URLSearchParams(window.location.search);
  const queryValue = urlParams.get("searchQuery");
  const query = queryValue === "undefined" ? null : queryValue;

  const [searchHeading, setSearchHeading] = useState<string>("");
  const [totalResults, setTotalResults] = useState<number>(0);

  const { ready } = useCxp();

  const {
    data: productSuggestions,
    error: productSuggestionsError,
    isLoading: productSuggestionsIsLoading,
    isValidating: productSuggestionsIsValidating,
  } = useSWR(
    query
      ? [
          "getProductSuggestions",
          {
            searchQuery: query,
            sort: {
              sortAttribute: "Pop Code",
              sortType: "DESC",
            },
          },
        ]
      : [
          "getProductSuggestsions",
          {
            searchCriteria: "ALL",
            sort: {
              sortAttribute: "Pop Code",
              sortType: "DESC",
            },
          },
        ],
    (vars: any) =>
      pzFetcher(vars[1]).then((m) => {
        let results = m as ProductResults;

        const totalString = results?.partSuggestList?.find(
          (p) => p.suggestId === 0
        )?.suggestString;

        if (totalString) {
          const totalREgex = /\d{1,4}/;
          results.total = +(totalString?.match(totalREgex)?.[0] ?? 0);
          results.partSuggestList = results.partSuggestList.filter(
            (p) => p.suggestId !== 0
          );
        } else {
          results.total = 0;
        }

        return results;
      }),
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    }
  );
  useEffect(() => {
    document.body.classList.add("site-search");
  }, []);

  useEffect(() => {
    let totalResults = 0;
    if (productSuggestions?.partSuggestList) {
      totalResults =
        productSuggestions.partSuggestList.length +
        Number(totalSitefinitySearchResults);
    } else {
      totalResults = Number(totalSitefinitySearchResults);
    }
    setSearchHeading(
      `Showing ${totalResults} result${totalResults > 1 ? "(s)" : ""} ${
        query ? `for ${query}` : ""
      }`
    );
    setTotalResults(totalResults);
  }, [productSuggestions]);

  return (
    container &&
    ReactDOM.createPortal(
      <div className="product-search">
        {productSuggestionsIsLoading ? (
          <div className="product-search__loading-overlay">
            <div className="lds-spinner">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        ) : (
          ""
        )}
        {totalResults > 0 ? (
          <h3 className="product-search__heading">{searchHeading}</h3>
        ) : (
          <p className="product-search__no-results">
            Your search for "{query}" has found 0 results.
          </p>
        )}
        {productSuggestions?.partSuggestList &&
          productSuggestions.partSuggestList.length > 0 &&
          currentPage <= 1 && (
            <ul className="product-search__list">
              {productSuggestions.partSuggestList.map((r) => (
                <li className="product-card">
                  <a
                    href={cxpJS.getProductUrl(
                      r.originalPartNumber,
                      r.productType
                    )}
                    className="product-card__link"
                  >
                    <img
                      src={
                        r.tnImage ??
                        "https://dd06uqodq6kyk.cloudfront.net/opt-us/images/logo/opt-logo-2.png"
                      }
                      className="product-card__image"
                    />
                    <div className="product-card__content">
                      <h3 className="product-card__heading">
                        {r.originalPartNumber}
                      </h3>
                      <p className="product-card__info">{r.suggestString}</p>
                    </div>
                  </a>
                </li>
              ))}
            </ul>
          )}
      </div>,
      container
    )
  );
}
