import { useMemo, useRef, useState } from "react";

import ReactDOM from "react-dom";
import { debounce } from "lodash";
import { useCxp } from "../../hooks/useCxp";
import useSWR from "swr";

export interface ProductResults {
  partSuggestList: PartResult[];
  total: number;
}

export interface PartResult {
  suggestString: string;
  suggestId: number;
  partNo: string;
  partType: string;
  productType: string;
  catalog: string;
  tnImage: string;
  originalPartNumber: string;
}

export interface ContentResults {
  TotalCount: number;
  SearchResults: ContentResult[];
}

export interface ContentResult {
  HighLighterResult: string;
  Language: string;
  Provider: string;
  Link: string;
  Title: string;
  ContentType: string;
  Id: string;
  ThumbnailUrl: string;
  PublicationDate: Date;
  LastModified: Date;
}

export const productSuggestionsApiEndpoint = () =>
  `/api/suggest`;

const contentSuggestionsApiEndpoint = (query: string) =>
  `/restapi/content/search?indexName=optimabatteries-site&query=${query}`;

const pzFetcher = (payload: { searchQuery: string }) => {
  return fetch(productSuggestionsApiEndpoint(), {
    method: "post",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(payload),
  }).then((res) => res.json());
};

const aeFetcher = (payload: string) => {
  return fetch(contentSuggestionsApiEndpoint(payload), {
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};

export default function SearchBox() {
  const container = document.getElementById("searchBox");
  const [query, setQuery] = useState<string | undefined>();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { ready } = useCxp();
  const resultLimit = 5;
  const inputChangeHandler = (e: any) => {
    if (!e.target.value) {
      setQuery(undefined);
    } else if (e.target.value.length < 2) {
      setQuery(undefined);
    } else {
      setQuery(e.target.value);
    }
  };

  const debounceInputChangeHandler = useMemo(
    () => debounce(inputChangeHandler, 300),
    []
  );

  const handleInputSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      const value = e.currentTarget.value;
      const searchTerm = query ? query : value;
      window.location.href = `/site-search?indexCatalogue=optimabatteries-site&searchQuery=${searchTerm}`;
    }
  };

  const handleSearchSubmit = () => {
    const searchTerm =
      inputRef.current && inputRef.current.value !== ""
        ? inputRef.current.value
        : undefined;
    window.location.href = `/site-search?indexCatalogue=optimabatteries-site&searchQuery=${searchTerm}`;
  };

  const {
    data: productSuggestions,
    error: productSuggestionsError,
    isLoading: productSuggestionsIsLoading,
    isValidating: productSuggestionsIsValidating,
  } = useSWR(
    query
      ? [
          "getProductSuggestions",
          {
            searchQuery: query,
            sort: {
              sortAttribute: "Pop Code",
              sortType: "DESC",
            },
          },
        ]
      : null,
    (vars) =>
      pzFetcher(vars[1]).then((m) => {
        let results = m as ProductResults;

        const totalString = results?.partSuggestList?.find(
          (p) => p.suggestId === 0
        )?.suggestString;

        if (totalString) {
          const totalREgex = /\d{1,4}/;
          results.total = +(totalString?.match(totalREgex)?.[0] ?? 0);
          results.partSuggestList = results.partSuggestList
            .filter((p) => p.suggestId !== 0)
            .slice(0, resultLimit);
        } else {
          results.total = 0;
        }

        return results;
      }),
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    }
  );

  const {
    data: contentSuggestions,
    error: contentSuggestionsError,
    isLoading: contentSuggestionsIsLoading,
    isValidating: contentSuggestionsIsValidating,
  } = useSWR(
    query ? ["getContentSuggestions", query] : null,
    (vars) => aeFetcher(vars[1]).then((m) => m as ContentResults),
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    }
  );

  return (
    container &&
    ReactDOM.createPortal(
      <div className="search-box__container">
        <div className="search-box__search">
          <label className="search-box__label">
            <input
              type="text"
              className="form-control search-box__input"
              placeholder="Search"
              autoComplete="off"
              ref={inputRef}
              onKeyUp={handleInputSubmit}
              onChange={debounceInputChangeHandler}
            />
            <div className="search-box__tooltip" hidden>
              Search by Part No, Product Type, Product Line, Keyword,
              Interchange, Product Description
            </div>
          </label>
          {query && query.length > 0 && (
            <button
              type="button"
              className="search-box__clear"
              onClick={() => {
                if (inputRef.current) {
                  inputRef.current.value = "";
                }
              }}
            >
              Clear
            </button>
          )}
          <button
            type="button"
            className="search-box__submit"
            onClick={handleSearchSubmit}
          >
            Search
          </button>
        </div>
        {(((!contentSuggestionsIsLoading && !productSuggestionsIsLoading) && contentSuggestions?.SearchResults && contentSuggestions.SearchResults.length > 0) || productSuggestions?.partSuggestList) && (
            <div className="search-suggestions">
              <div className="search-suggestions__container">
                {productSuggestions?.partSuggestList &&
                  productSuggestions.partSuggestList.length > 0 && (
                    <div className="search-suggestions__content">
                      <h3 className="search-suggestions__heading">Part</h3>
                      {
                        productSuggestions.total > 0
                        ?
                        <a href={`/search?str=${query}&cat1=0&sort=1&sortType=DESC`} className="search-suggestions__link search-suggestions__link--secondary">View all {productSuggestions.total} parts</a>
                        : productSuggestions.total === 1
                        ? <a href={`/products/${productSuggestions.partSuggestList[0].productType}-${productSuggestions.partSuggestList[0].originalPartNumber}`} className="search-suggestions__link search-suggestions__link--secondary">View Product</a>
                        : ''
                      }
                      <ul className="search-suggestions__list">
                        {productSuggestions.partSuggestList.map((r) => (
                          <li className="search-suggestions__item">
                            <button
                              type="button"
                              className="search-suggestions__button"
                              onClick={() => {
                                var url = cxpJS.getProductUrl(
                                  r.originalPartNumber,
                                  r.productType
                                );
                                if (url && url.length > 0) {
                                  window.location.href = url;
                                }
                              }}
                            >
                              <img
                                src={
                                  r.tnImage ??
                                  "https://dd06uqodq6kyk.cloudfront.net/opt-us/images/logo/opt-logo-2.png"
                                }
                                className="search-suggestions__image search-suggestions__image--secondary"
                                alt={r.suggestString}
                                height="50"
                              />
                              <span className="search-suggestions__title">{r.suggestString}</span>
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                {contentSuggestions?.SearchResults &&
                  contentSuggestions.SearchResults.length > 0 && (
                    <div className="search-suggestions__content">
                      <h3 className="search-suggestions__heading">Content</h3>
                      <ul className="search-suggestions__list">
                        {contentSuggestions.SearchResults.slice(0,resultLimit).map((r) => (
                          <li className="search-suggestions__item">
                            <a href={r.Link} className="search-suggestions__link">
                              <img
                                src={
                                  r.ThumbnailUrl ??
                                  "https://dd06uqodq6kyk.cloudfront.net/opt-us/images/logo/opt-logo-2.png"
                                }
                                width="50"
                                height="50"
                                className="search-suggestions__image"
                                alt={r.Title}
                              />
                              <span className="search-suggestions__title">{r.Title}</span>
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
              </div>
              {
                (productSuggestions?.partSuggestList && productSuggestions.partSuggestList.length > 0) || (contentSuggestions?.SearchResults && contentSuggestions.SearchResults.length > 0)
                ?
                <a href={`/site-search?indexCatalogue=optimabatteries-site&searchQuery=${query}`} className="search-suggestions__link search-suggestions__link--secondary">View All</a>
                : ''
              }
          </div>
        )}
      </div>,
      container
    )
  );
}
