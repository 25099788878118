import CxpNav from "./components/navigation/cxpNav";
import FindARetailer from "./components/retailFinder/findARetailer";
import FindYourBattery from "./components/findYourBattery";
import MobileUserLinks from "./components/navigation/mobileUserLinks";
import ProductSearch from "./components/productSearch";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { BrowserRouter as Router } from "react-router-dom";
import SearchBox from "./components/navigation/searchBox";
import Username from "./components/user/username";
import CxpCart from "./components/navigation/cxpCart";
import FindYourStore from "./components/findYourStore/findYourStore";
import Svgs from "./components/svgs";

const findYourBattery = document.getElementById("findYourBattery");
const findARetailer = document.getElementById("findARetailer");
const searchBox = document.getElementById("searchBox");
const findYourStore = document.getElementById("find-your-store");
const cxpNav = document.getElementById("cxpNav");
const userName = document.getElementById("pzUsername");
const mobileUserLinks = document.getElementById("pzMobileUserLinks");
const productSearch = document.getElementById("productSearch");
const cxpCart = document.getElementById("cxpCart");

const batteryFinderPage = document.getElementById(
  "batteryFinderPage"
) as HTMLInputElement;
const retailFinderPage = document.getElementById(
  "retailFinderPage"
) as HTMLInputElement;

function App() {
  return (
    <Router>
      <QueryParamProvider
        options={{ enableBatching: true }}
        adapter={ReactRouter6Adapter}
      >
        <Svgs />
        {cxpNav && <CxpNav />}
        {cxpCart && <CxpCart />}
        {mobileUserLinks && <MobileUserLinks />}
        {productSearch && <ProductSearch />}
        {userName && <Username />}
        {searchBox && <SearchBox />}
        {findARetailer && <FindARetailer portal={true} />}
        {findYourBattery && <FindYourBattery />}
        {findYourStore && (
          <FindYourStore
            onFinish={(zip, radius, country) => {
              window.location.href = `/where-to-buy?zipCode=${zip}&radius=${radius}&country=${country}`;
            }}
          />
        )}
      </QueryParamProvider>
    </Router>
  );
}

export default App;
