import { useEffect, useState } from "react";

import ReactDOM from "react-dom";
import { useCxp } from "../../hooks/useCxp";

export default function CxpCart() {
	// mobile cart toggle on header footer page template
  const container = document.getElementById("cxpCart");
  const { ready } = useCxp();
  const [cartCount, setCartCount] = useState(0);

  useEffect(() => {
    if (ready) {
      const count = cxpJS.getCartCount();
      if (count) {
        setCartCount(count);
      } else {
        setCartCount(0);
      }
    }
  }, [ready]);

  return (
    container &&
    ReactDOM.createPortal(
        <div className="cxp-nav">
			<a
			href="/view-cart"
			className="cxp-nav__link"
			aria-label="view cart"
			>
			<span className="cxp-nav__info">{cartCount}</span>
			<svg className="cxp-nav__icon" aria-hidden="true">
				<use href="/ResourcePackages/Talon/assets/dist/sprites/svg-optima.svg#icon-cart"></use>
			</svg>
			</a>
        </div>,
      container
    )
  );
}
